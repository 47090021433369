import { getFooterData, getHomepageData, getNavigationData } from "@/api";
import { CrumbsItemAtom, HideCrumbsAtom } from "@/atoms";
import { IPageDTOResponse } from "@/interfaces/IPageDTO";
import { NextPage } from "next";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from 'react-i18next';
import { ModuleMatcher2 } from "@/components";

import PagePathAtom from "@/atoms/PagePathAtom";
import Head from "next/head";
import getTargetUrl from "@/api/getTargetUrl";
import Script from "next/script";

export async function getServerSideProps(ctx: any) {

  const { lang } = ctx.params;

  ctx.res.setHeader('Cache-Control', 'public, max-age=120');

  const data = await getHomepageData();

  const footerData = await getFooterData();

  const navigationData = await getNavigationData();

  //NOTE: MODULEMATCHER SERVER SIDE PREFETCH
  // const dataPassedToModuleMatcher = data?.data[0].lstModule;
  const dataPassedToModuleMatcher = data.data?.find((item: { xtaxAirports: { ti: any[]; }; }) => item.xtaxAirports.ti.find(airportItem => airportItem.label.toLowerCase() === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE?.toLowerCase()));

  const excludes = new Array;

  const dataPassToModuleMatcher = await Promise.all(dataPassedToModuleMatcher!.lstModule?.filter(item => !excludes?.includes(item.xconModules.linkedcontenttype)).map( async (moduleItem) => {

    var moduleData: any;

    switch (moduleItem.xconModules.linkedcontenttype) {
    
      case "m01":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m02":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m03":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m04":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m05":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m06":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl)
        //TODO: fetch delle card?
      case "m07":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m08":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
        //TODO: fetch delle card?
      case "m09":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m10":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m11":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m12":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m13":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m14":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m15":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
        //TODO: check fetch dentro questo modulo
      case "m16":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
        //TODO: fetch delle card?
      case "m17":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m18":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m19":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
      case "m20":
        moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
    }

    return {
      linkedcontenttype: moduleItem.xconModules.linkedcontenttype,
      id: moduleItem.id,
      moduleData: moduleData
    };

}))

  return {
    props: {
      lang,
      data,
      footerData,
      navigationData,
      dataPassToModuleMatcher
    },
  };
}

interface IHomepageProps {
  lang: string;
  data: IPageDTOResponse;
  navigationData: any;
  getTargetUrl: any;
  dataPassToModuleMatcher: any;
}

const Homepage: NextPage<IHomepageProps> = ({ lang, data, navigationData, dataPassToModuleMatcher }: {
  data: IPageDTOResponse;
  lang: string;
  navigationData: any;
  dataPassToModuleMatcher: any;
}) => {

  const pageData = data?.data[0];
  const pagePath = pageData?.xtaxNavigationMenu?.ti && pageData.xtaxNavigationMenu.ti[0].path ? pageData.xtaxNavigationMenu.ti[0].path : '1/2'
  const { t, i18n } = useTranslation();

  const setPagePath = useSetRecoilState(PagePathAtom);
  const setCrumbsItem = useSetRecoilState(CrumbsItemAtom);
  const setHideCrumbs = useSetRecoilState(HideCrumbsAtom);

  useEffect(() => {
    setPagePath(pagePath)
    setCrumbsItem(navigationData)
    setHideCrumbs(pageData?.flgHideNavigationéath)
  }, [pageData])

  useEffect(() => {
    i18n.changeLanguage(lang.toLowerCase())
  }, [lang])

  return (
    <>
      <Head>
        
        <title>{pageData.lblSeoTitle[lang]}</title>
        <meta name="description" content={pageData.lblSeoDescription[lang]}></meta>
        <meta name="google-site-verification" content="O9KGBBGOrdaWpgZGBNlsq0Y1qBChD-aV62vMbPF8X1M" />
        <script src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}`}></script>

        {process.env.NEXT_PUBLIC_IATA_AIRPORT_REFERENCE === 'mxp' ?
          <>
            <script type="application/javascript" src="https://ced.sascdn.com/tag/2661/smart.js" async></script>
            <script type="application/javascript">
              {`
                var sas = sas || {};
                sas.cmd = sas.cmd || [];
                sas.cmd.push(function() {
                    sas.setup({ networkid: 2661, domain: 'https://www14.smartadserver.com', async: true });
                });
                sas.cmd.push(function() {
                    sas.call("onecall", {
                        siteId: 299227,
                        pageId: 1095953,
                        formats: [
                            { id: 56461 },
                            { id: 56463 }
                        ],
                        target: 'lang=${lang}'
                    });
                });
              `}
            </script>
          </> : 
          <>
            <script type="application/javascript" src="https://ced.sascdn.com/tag/2661/smart.js" async></script>
            <script>
              {`
                var sas = sas || {};
                sas.cmd = sas.cmd || [];
                sas.cmd.push(function() {
                    sas.setup({ networkid: 2661, domain: "https://www14.smartadserver.com", async: true });
                });
                sas.cmd.push(function() {
                    sas.call("onecall", {
                        siteId: 299226,
                        pageId: 1095950,
                        formats: [
                             { id: 56461 },
                             { id: 56463 }
                        ],
                        target: 'lang=${lang}'
                    });
                });
              `}
            </script>
          </>
        }
      </Head>

      <main>
        <ModuleMatcher2 lang={lang} moduleData={dataPassToModuleMatcher} />

        <div id="sas_56461"></div>
        <Script type="application/javascript">{`
          var sas = sas || {};
          sas.cmd = sas.cmd || [];
          sas.cmd.push(function() {
              sas.render("56461");
          });
        `}
        </Script>

        <div id="sas_56463"></div>
        <Script type="application/javascript">{`
          var sas = sas || {};
          sas.cmd = sas.cmd || [];
          sas.cmd.push(function() {
              sas.render("56463");
          });
        `}
        </Script>

      </main>
    </>
  );
};

export default Homepage;
